import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {StaticImage} from 'gatsby-plugin-image';
import {graphql} from 'gatsby';
import CompanySettings from "../../../constants/company/settings";

function handleScrollTo(elmID) {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: document.getElementById(elmID).offsetTop - 170
  });
}

const IndexPage = () => {

  useEffect(() => {
    if (document.location.hash === '#gotoAmericas') {
      handleScrollTo("digilockAmericas");
    }
    if (document.location.hash === '#gotoEurope') {
      handleScrollTo("digilockEurope");
    }
    if (document.location.hash === '#gotoAsia') {
      handleScrollTo("digilockAsia");
    }

  }, []);

  const {t} = useTranslation();

  //asia email
  function gtag_report_conversion_asia_email(url) {
    let callback = function () {
      if (typeof(url) != 'undefined') {
        // window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      'send_to': 'AW-829366430/MlX0CL2d4poYEJ7BvIsD',
      'event_callback': callback
    });
    return false;
  }
  //asia phone
  function gtag_report_conversion_asia_call(url) {
    let callback = function () {
      if (typeof(url) != 'undefined') {
        // window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      'send_to': 'AW-829366430/G6sqCLed4poYEJ7BvIsD',
      'event_callback': callback
    });
    return false;
  }

  return (
      <Layout>
        <Seo
            title={t("about_offices_title")}
            description={t('des_about_offices')}
        />
        <div className="about offices">
          <section className="hero">
            <div className="container">
              <h1>Digilock Global Offices</h1>
            </div>
          </section>
          <section className="global-offices">
            <div className="container offices">
              <h3 id={'digilockAmericas'}>Digilock Americas</h3>
              <div>
                <figure>
                  <StaticImage
                      src="../../../images/about/digilock-americas.jpg"
                      width={342}
                      height={228}
                      formats={["auto", "webp", "avif"]}
                      alt="Digilock Petaluma"
                  />
                  <figcaption>
                    <span><Trans>Petaluma</Trans></span>
                    <p>1 Willowbrook Court</p>
                    <p>Petaluma, CA 94954</p>
                    <span><Trans>sales</Trans></span>
                    <p><Trans>email</Trans>: <a href={'mailto:' + CompanySettings.SALES_EMAIL}>{CompanySettings.SALES_EMAIL}</a></p>
                    <p>
                      <Trans>phone</Trans>: <a href={'tel:' + CompanySettings.PHONE_SALES_AMERICAS}>{CompanySettings.PHONE_SALES_AMERICAS}</a>
                    </p>
                    <span><Trans>support</Trans></span>
                    <p>
                      <Trans>email</Trans>: <a href={'mailto:' + CompanySettings.SUPPORT_EMAIL}>{CompanySettings.SUPPORT_EMAIL}</a>
                    </p>
                    <p>
                      <Trans>toll_free</Trans>: <a href={'tel:' + CompanySettings.PHONE_SUPPORT_AMERICAS}>{CompanySettings.PHONE_SUPPORT_AMERICAS}</a>
                    </p>
                  </figcaption>
                </figure>
                <figure>
                  <StaticImage
                      src="../../../images/about/digilock-showroom-chicago.jpg"
                      width={342}
                      height={228}
                      formats={["auto", "webp", "avif"]}
                      alt="Digilock Chicago"
                  />
                  <figcaption>
                    <Trans>Chicago</Trans>
                    <p>theMART, Floor 10, Suite 1098</p>
                    <p>Chicago, IL 60654</p>
                    <span><Trans>sales</Trans></span>
                    <p><Trans>email</Trans>: <a href={'mailto:' + CompanySettings.SALES_EMAIL}>{CompanySettings.SALES_EMAIL}</a></p>
                    <p>
                      <Trans>phone</Trans>: <a href={'tel:' + CompanySettings.PHONE_SALES_AMERICAS}>{CompanySettings.PHONE_SALES_AMERICAS}</a>
                    </p>
                    <span><Trans>support</Trans></span>
                    <p>
                      <Trans>email</Trans>: <a href={'mailto:' + CompanySettings.SUPPORT_EMAIL}>{CompanySettings.SUPPORT_EMAIL}</a>
                    </p>
                    <p>
                      <Trans>toll_free</Trans>: <a href={'tel:' + CompanySettings.PHONE_SUPPORT_AMERICAS}>{CompanySettings.PHONE_SUPPORT_AMERICAS}</a>
                    </p>
                  </figcaption>
                </figure>
                <figure>
                  <StaticImage
                      src="../../../images/about/digilock-new-york-city.jpg"
                      width={342}
                      height={228}
                      formats={["auto", "webp", "avif"]}
                      alt="Digilock New York"
                  />
                  <figcaption>
                    <Trans>New York</Trans>
                    <p>400 Madison Avenue</p>
                    <p>New York, NY 10017</p>
                    <span><Trans>sales</Trans></span>
                    <p><Trans>email</Trans>: <a href={'mailto:' + CompanySettings.SALES_EMAIL}>{CompanySettings.SALES_EMAIL}</a></p>
                    <p>
                      <Trans>phone</Trans>: <a href={'tel:' + CompanySettings.PHONE_SALES_AMERICAS}>{CompanySettings.PHONE_SALES_AMERICAS}</a>
                    </p>
                    <span><Trans>support</Trans></span>
                    <p>
                      <Trans>email</Trans>: <a href={'mailto:' + CompanySettings.SUPPORT_EMAIL}>{CompanySettings.SUPPORT_EMAIL}</a>
                    </p>
                    <p>
                      <Trans>toll_free</Trans>: <a href={'tel:' + CompanySettings.PHONE_SUPPORT_AMERICAS}>{CompanySettings.PHONE_SUPPORT_AMERICAS}</a>
                    </p>
                  </figcaption>
                </figure>
                <figure>
                  <StaticImage
                      src="../../../images/about/digilock-houston.jpg"
                      width={342}
                      height={228}
                      formats={["auto", "webp", "avif"]}
                      alt="Offices"
                  />
                  <figcaption>
                    <Trans>Houston</Trans>
                    <p>3367 N Sam Houston Pkwy W</p>
                    <p>Houston, TX 77038</p>
                    <span><Trans>sales</Trans></span>
                    <p><Trans>email</Trans>: <a href={'mailto:' + CompanySettings.SALES_EMAIL}>{CompanySettings.SALES_EMAIL}</a></p>
                    <p>
                      <Trans>phone</Trans>: <a href={'tel:' + CompanySettings.PHONE_SALES_AMERICAS}>{CompanySettings.PHONE_SALES_AMERICAS}</a>
                    </p>
                    <span><Trans>support</Trans></span>
                    <p>
                      <Trans>email</Trans>: <a href={'mailto:' + CompanySettings.SUPPORT_EMAIL}>{CompanySettings.SUPPORT_EMAIL}</a>
                    </p>
                    <p>
                      <Trans>toll_free</Trans>: <a href={'tel:' + CompanySettings.PHONE_SUPPORT_AMERICAS}>{CompanySettings.PHONE_SUPPORT_AMERICAS}</a>
                    </p>
                  </figcaption>
                </figure>

              </div>
              <div>
                <div>
                  <h3 id={'digilockEurope'}>Digilock Europe BV</h3>
                  <figure>
                    <StaticImage
                        src="../../../images/about/digilock-europe-netherlands.jpg"
                        width={342}
                        height={228}
                        formats={["auto", "webp", "avif"]}
                        alt="Offices"
                    />
                    <figcaption>
                      <Trans>The Netherlands</Trans>
                      <p>Schillingweg 22 2153 PL, Nieuw-Vennep</p>
                      <p>Amsterdam, The Netherlands</p>
                      <span><Trans>sales</Trans></span>
                      <p><Trans>email</Trans>: <a href={'mailto:' + CompanySettings.SALES_EMAIL_EUROPE}>{CompanySettings.SALES_EMAIL_EUROPE}</a></p>
                      <p>
                        <Trans>phone</Trans>: <a href={'tel:' + CompanySettings.PHONE_SALES_EUROPE}>{CompanySettings.PHONE_SALES_EUROPE}</a>
                      </p>
                      <span><Trans>support</Trans></span>
                      <p>
                        <Trans>email</Trans>: <a href={'mailto:' + CompanySettings.SUPPORT_EMAIL_EUROPE}>{CompanySettings.SUPPORT_EMAIL_EUROPE}</a>
                      </p>
                      <p>
                        <Trans>toll_free</Trans>: <a href={'tel:' + CompanySettings.PHONE_SUPPORT_EUROPE}>{CompanySettings.PHONE_SUPPORT_EUROPE}</a>
                      </p>
                    </figcaption>
                  </figure>
                </div>
                <div>
                  <h3 id={'digilockAsia'}>Digilock Asia</h3>
                  <figure>
                    <StaticImage
                        src="../../../images/about/digilock-asia.jpg"
                        width={342}
                        height={228}
                        formats={["auto", "webp", "avif"]}
                        alt="Offices"
                    />
                    <figcaption>
                      <Trans>Hong Kong</Trans>
                      <p>Flat B, 27th Floor Grandion Plaza</p>
                      <p>No. 932 Cheung Sha Wan Road</p>
                      <p>Lai Chi Kok, Kowloon, Hong Kong</p>
                      <span><Trans>sales</Trans></span>
                      <p><Trans>email</Trans>: <a href={'mailto:' + CompanySettings.SALES_EMAIL_ASIA}>{CompanySettings.SALES_EMAIL_ASIA}</a></p>
                      <p>
                        <Trans>phone</Trans>: <a href={'tel:' + CompanySettings.PHONE_SALES_ASIA}>{CompanySettings.PHONE_SALES_ASIA}</a>
                      </p>
                      <span><Trans>support</Trans></span>
                      <p>
                        <Trans>email</Trans>: <a href={'mailto:' + CompanySettings.SUPPORT_EMAIL_ASIA}>{CompanySettings.SUPPORT_EMAIL_ASIA}</a>
                      </p>
                      <p>
                        <Trans>toll_free</Trans>: <a href={'tel:' + CompanySettings.PHONE_SUPPORT_ASIA}>{CompanySettings.PHONE_SUPPORT_ASIA}</a>
                      </p>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
